import Container from '@mui/material/Container';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Header from './Header/Header';
import SidebarButton from './SidebarButton/SidebarButton';

import type { SideBarButtons } from './SidebarButton/SidebarButton';

const drawerWidth = '10rem';

const DrawerContainer = styled(Container)<{ fullWidth: boolean }>(
    ({ theme, fullWidth }) => `
        position: relative;
        display: ${fullWidth ? 'none' : 'inherit'};
        @media (max-width: ${theme.breakpoints.values.md}px) {
            display: none;
        }
    `
);

const StyledDrawerContent = styled('div')<{ floating: boolean }>(
    ({ floating }) => `
    position: ${floating ? 'absolute' : 'fixed'};
    overflow: auto;
    margin-top: 2rem;
    margin-left: 0.5rem;
`
);

const StyledContainer = styled('div')(
    ({ theme }) => `
    min-height: 100vh;
    width: 100%;
    background-color: ${theme.palette.grey[400]};
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
`
);

const StyledContent = styled('div')(({ theme }) => ({
    flexGrow: 1,
    display: 'flex',
    padding: '2rem',
    [theme.breakpoints.down('sm')]: {
        padding: '1rem',
        paddingLeft: '0rem',
        paddingRight: '0rem'
    }
}));

const StyledMaterialContainer = styled(Container)<{ fullWidth: boolean }>(
    ({ theme, fullWidth }) => ({
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down(1520)]: {
            marginLeft: fullWidth ? 'auto' : drawerWidth
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 0
        }
    })
);

type MainContainerProps =
    | {
          public?: false;
          firstName: string;
          photoURL: string;
          signOut: () => void;
          sideBarButtons: SideBarButtons;
          scrollButtons?: SideBarButtons;
          fullWidth?: boolean;
          floating?: boolean;
          children: any;
      }
    | { public: true; fullWidth: true; children: any };

export default function MainContainer(props: MainContainerProps) {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        if (hash) {
            // If there's a hash, scroll to the element
            const elementId = hash.replace('#', '');
            const element = document.getElementById(elementId);

            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            // If no hash, scroll to the top
            window.scrollTo(0, 0);
        }
    }, [pathname, hash]);

    return (
        <StyledContainer>
            {!props.public && (
                <Header
                    firstName={props.firstName}
                    photoURL={props.photoURL}
                    signOut={props.signOut}
                    sideBarButtons={props.sideBarButtons}
                    public={props.public}
                />
            )}
            {props.public && <Header public={props.public} />}
            {!props.public && (
                <DrawerContainer maxWidth='xl' fullWidth={props.fullWidth}>
                    <StyledDrawerContent floating={props.floating}>
                        <List>
                            {(props.scrollButtons
                                ? props.scrollButtons
                                : props.sideBarButtons
                            ).map((sidebar, index) => (
                                <SidebarButton
                                    text={sidebar.text}
                                    to={sidebar.to}
                                    active={sidebar.active}
                                    key={index}
                                />
                            ))}
                        </List>
                    </StyledDrawerContent>
                </DrawerContainer>
            )}
            <StyledContent>
                <StyledMaterialContainer>
                    {props.children}
                </StyledMaterialContainer>
            </StyledContent>
        </StyledContainer>
    );
}
