/* eslint-disable max-lines */
import moment from 'moment';
import React, {
    Fragment,
    useContext,
    useEffect,
    useMemo,
    useState
} from 'react';
import {
    Redirect,
    useLocation,
    useParams,
    useRouteMatch
} from 'react-router-dom';

import { convertCreateInterviewDetailsToCreateInterviewDetailsRequest } from '../../../services/convert/interview';
import { createEmptyJob } from '../../../services/convert/job';
import { createInterview } from '../../../services/interviews';
import { createJob, getJob } from '../../../services/jobs';
import { getEligibleUserCount } from '../../../services/user';
import { CreateInterviewDetails } from '../../../types/interview';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { FirebaseContext } from '../../context/FirebaseContext';
import { UserContext } from '../../context/UserContext';
import MainContainer from '../../global/layouts/MainContainer/MainContainer';
import {
    useCreateJobSidebarButtons,
    useDefaultSidebarButtons
} from '../../global/layouts/MainContainer/SidebarButton/SidebarList';
import { DateYMDString } from '../BookInterview/types';

import JobPostRoutes, { routes } from './JobPostRoutes';

export default function JobPost() {
    const [redirect, setRedirect] = useState<string>('');
    const location = useLocation();
    const { path } = useRouteMatch();
    const { companyID, jobID } =
        useParams<{ uid: string; companyID: string; jobID: string }>();
    const [query, setQuery] = useState<CandidateSearch>({});

    const [candidateCount, setCandidateCount] = useState(0);

    const {
        user,
        company: currentCompany,
        setCompanyID
    } = useContext(UserContext);
    const { auth } = useContext(FirebaseContext);

    const [job, setJob] = useState<SimpleJob>(createEmptyJob());

    const [interview, setInterview] = useState<CreateInterviewDetails>({
        id: 0,
        jobID: 0,
        interviewerID: user.id,
        split: true,
        splitTime: 8,
        details: []
    });

    const defaultSideBarButtons = useDefaultSidebarButtons(user.userID);
    const scrollButtons = useCreateJobSidebarButtons(
        user.userID,
        currentCompany.id,
        job.id
    );

    useEffect(() => {
        if (companyID) {
            setCompanyID(parseInt(companyID, 10));
        }
    }, [companyID, setCompanyID]);

    const flowType = useMemo(
        () => (location.pathname.includes('/edit/') ? 'edit' : 'create'),
        [location.pathname]
    );

    const getPageURL = (
        page: keyof typeof routes,
        reverse: boolean = false
    ) => {
        let baseURL = `/${flowType}/${user.userID}/job/${currentCompany.id}`;
        if (flowType === 'edit') {
            baseURL += `/${job.id}`;
        }
        if (reverse) {
            return `${baseURL}/${routes[page].prev}`;
        }
        return `${baseURL}/${routes[page].next}`;
    };

    useEffect(() => {
        if (jobID) {
            getJob(parseInt(jobID, 10)).then((jobResponse: SimpleJob) => {
                setJob(jobResponse);
            });
        }
    }, [jobID, setJob]);

    const setJobAndRedirect = (
        newJob: SimpleJob,
        page: keyof typeof routes
    ) => {
        setJob(newJob);
        setRedirect(getPageURL(page));
    };

    const [date, setDate] = useState<DateYMDString>(
        moment().format('YYYY-MM-DD') as DateYMDString
    );

    const submitPosting = (
        jobDetails: SimpleJob,
        interviewDetails: CreateInterviewDetails
    ) => {
        createJob({
            ...jobDetails,
            companyID: currentCompany.id,
            createdByID: user.id
        }).then((jobResponse: SimpleJob) => {
            setJob(jobResponse);
            const interviewRequest =
                convertCreateInterviewDetailsToCreateInterviewDetailsRequest(
                    interviewDetails,
                    jobResponse.id
                );
            createInterview(interviewRequest).then(() => {
                setRedirect(getPageURL('complete'));
            });
        });
    };

    useEffect(() => {
        getEligibleUserCount({}).then((count: number) => {
            setCandidateCount(count);
        });
    }, [setCandidateCount]);

    const onClose = async () => {
        setRedirect(`/secure/${user.userID}/${user.levylRole}/dashboard`);
    };

    return (
        <Fragment>
            {location.pathname.includes('/edit/') ? (
                <MainContainer
                    sideBarButtons={defaultSideBarButtons}
                    firstName={user.name.firstName}
                    photoURL={user.name.imageURL}
                    signOut={() => {
                        auth?.signOut();
                    }}
                    scrollButtons={scrollButtons}
                >
                    <JobPostRoutes
                        path={path}
                        job={job}
                        setJobAndRedirect={setJobAndRedirect}
                        candidateCount={candidateCount}
                        setCandidateCount={setCandidateCount}
                        query={query}
                        setQuery={setQuery}
                        date={date}
                        setDate={setDate}
                        interview={interview}
                        setInterview={setInterview}
                        submitPosting={submitPosting}
                        getPageURL={getPageURL}
                        onClose={onClose}
                    />
                </MainContainer>
            ) : (
                <MainContainer public fullWidth>
                    <JobPostRoutes
                        path={path}
                        job={job}
                        setJobAndRedirect={setJobAndRedirect}
                        candidateCount={candidateCount}
                        setCandidateCount={setCandidateCount}
                        query={query}
                        setQuery={setQuery}
                        date={date}
                        setDate={setDate}
                        interview={interview}
                        setInterview={setInterview}
                        submitPosting={submitPosting}
                        getPageURL={getPageURL}
                        onClose={onClose}
                    />
                </MainContainer>
            )}
            {redirect && <Redirect to={redirect} />}
        </Fragment>
    );
}
