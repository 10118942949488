import AddIcon from '@mui/icons-material/Add';
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import RemoveIcon from '@mui/icons-material/Remove';
import { styled, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { getCompanyStatistics } from '../../../services/company';
import { UserContext } from '../../context/UserContext';
import { BackButton } from '../../global/components/Button/Button';
import StyledCard, { BaseContainer } from '../../global/Core';

import PieChart from './PieChart';

const StyledAccordion = styled(Accordion)(() => ({
    border: 'none',
    marginBottom: '1rem',
    boxShadow: 'none',
    '&:before': {
        display: 'none'
    }
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
    backgroundColor: '#F5F3FF',
    padding: '0.5rem 2rem 0.5rem 2rem'
}));

const TotalCard = styled('div')(
    ({ theme }) => `
    border-radius: 1rem;
    padding: 1rem;
    background-color: ${theme.palette.primary.light};
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
`
);

export default function CompanyStatistics() {
    const { company: currentCompany, setCompanyID } = useContext(UserContext);
    const { companyID } = useParams<{ companyID: string }>();
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [jobOffers, setJobOffers] = useState<any>({});
    const [noJobOffers, setNoJobOffers] = useState<any>({});
    const [expanded, setExpanded] = useState<string | false>(false);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleChange =
        (panel: string) =>
        (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const history = useHistory();

    useEffect(() => {
        if (companyID) {
            setCompanyID(parseInt(companyID, 10));
        }
    }, [companyID, setCompanyID]);

    useEffect(() => {
        if (currentCompany.id) {
            getCompanyStatistics(currentCompany.id).then((response: any) => {
                setTotalCandidates(response.total_candidates);
                setJobOffers(response.job_offers);
                setNoJobOffers(response.no_job_offers);
            });
        }
    }, [currentCompany.id, setJobOffers, setNoJobOffers, setTotalCandidates]);

    return (
        <BaseContainer>
            <StyledCard>
                <BackButton
                    onClick={() => {
                        history.goBack();
                    }}
                    style={{ padding: '0.5rem' }}
                    startIcon={
                        <KeyboardBackspace
                            sx={{ marginRight: '0rem', marginLeft: '0rem' }}
                        />
                    }
                    color='primary'
                    aria-label='back button'
                />
                <Typography
                    variant='body2'
                    style={{ marginTop: '1.5rem', marginBottom: '1rem' }}
                >
                    {'Analytics'}
                </Typography>
                <TotalCard>
                    <Typography
                        variant={'h2'}
                        style={{
                            display: 'flex'
                        }}
                        fontSize={'1.25rem'}
                    >
                        {'Total interviews - '}
                        {totalCandidates}
                    </Typography>
                </TotalCard>
                {[
                    { id: 'panel1', title: 'Job Offers', data: jobOffers },
                    {
                        id: 'panel2',
                        title: 'Interviews - No Job Offers',
                        data: noJobOffers
                    }
                ].map((panel) => (
                    <StyledAccordion
                        expanded={expanded === panel.id}
                        onChange={handleChange(panel.id)}
                        key={panel.id}
                    >
                        <StyledAccordionSummary
                            expandIcon={
                                expanded === panel.id ? (
                                    <RemoveIcon color='primary' />
                                ) : (
                                    <AddIcon color='primary' />
                                )
                            }
                            aria-controls={`${panel.id}bh-content`}
                            id={`${panel.id}bh-header`}
                        >
                            <Typography
                                variant='body2'
                                sx={{
                                    flexShrink: 0,
                                    fontWeight: 600
                                }}
                            >
                                {panel.title}
                            </Typography>
                        </StyledAccordionSummary>
                        <AccordionDetails sx={{ px: 9 }}>
                            <div>
                                <TotalCard>
                                    <Typography
                                        variant={'h2'}
                                        fontSize={'1.25rem'}
                                        style={{ display: 'flex' }}
                                    >
                                        {`Total ${panel.title}: ${panel.data?.total}`}
                                    </Typography>
                                </TotalCard>
                            </div>
                            <PieChart
                                title={'Race'}
                                data={panel.data?.ethnicity}
                                hide={width < 900}
                            />
                            <PieChart
                                title={'Gender'}
                                data={panel.data?.gender}
                                hide={width < 900}
                            />
                            <PieChart
                                title={'Age'}
                                data={panel.data?.age}
                                hide={width < 900}
                            />
                        </AccordionDetails>
                    </StyledAccordion>
                ))}
            </StyledCard>
        </BaseContainer>
    );
}
