import {
    useAuthenticatedKnockClient,
    useNotifications
} from '@knocklabs/react';
import { useEffect, useMemo, useRef, useState } from 'react';

const useNotificationFeed = () => {
    const knockApiKey = useMemo(() => {
        if (window.location.hostname === 'localhost') {
            return 'pk_test_vX8Zzgd7cbzyrWgQ2cuwg2TZVjJKrQzOieRwo7piOWE';
        }
        return 'pk_N0QOyTlF9Ty5UelfoD8Q7x_1ApsDBM9H4iENj0G_3sc';
    }, []);

    const knockClient = useAuthenticatedKnockClient(knockApiKey, '60');
    const feedClient = useNotifications(
        knockClient,
        '6e86e574-9574-4ebd-b63a-bd1d8e7c4c76'
    );

    const [items, setItems] = useState(feedClient.store.getState().items);
    const itemsRef = useRef(items);

    useEffect(() => {
        feedClient.fetch({ status: 'unread' }).then(() => {
            const newItems = feedClient.getState().items;
            if (JSON.stringify(newItems) !== JSON.stringify(itemsRef.current)) {
                setItems(newItems);
                itemsRef.current = newItems;
            }
        });

        const handleNewMessages = () => {
            const newItems = feedClient.getState().items;
            if (JSON.stringify(newItems) !== JSON.stringify(itemsRef.current)) {
                setItems(newItems);
            }
        };

        feedClient.on('items.received.realtime', handleNewMessages);

        return () =>
            feedClient.off('items.received.realtime', handleNewMessages);
    }, [feedClient]);

    return {
        items,
        feedClient
    };
};

export default useNotificationFeed;
