export interface Stats {
    [key: string]: number;
}

export type ColourStats = {
    [key: string]: { colour: string; value: number };
};

const colours = [
    '#6C757D',
    '#E4DFFF',
    '#4B4B4B',
    '#975DD5',
    '#D9D9D9',
    '#8D8FF1',
    '#6C757D',
    '#E4DFFF',
    '#4B4B4B',
    '#975DD5',
    '#D9D9D9',
    '#8D8FF1'
];

export const convertDataToPercent = (stats: Stats) => {
    const data: ColourStats = {};

    if (stats && Object.keys(stats).length > 0) {
        const total = Object.values(stats).reduce((acc, curr) => acc + curr, 0);
        let currentTotal = 0;

        Object.keys(stats).forEach((key, index) => {
            const percent = Math.round((stats[key] / total) * 100);
            data[key] = { colour: colours[index], value: percent };
            currentTotal += percent;
        });

        const diff = 100 - currentTotal;
        const keys = Object.keys(data);
        if (data[keys[keys.length - 1]]?.value) {
            data[keys[keys.length - 1]].value += diff;
        }
        return data;
    }

    return null;
};
