// import { Feed, FeedItem } from '@knocklabs/client';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, Grid, IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React from 'react';

type NotificationPanelType = {
    items: any;
    feedClient: any;
    isOpened: boolean;
    setOpen: (open: boolean) => void;
};

const NotificationPanel = ({
    items,
    feedClient,
    isOpened,
    setOpen
}: NotificationPanelType) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Drawer
                variant='persistent'
                onClose={handleClose}
                anchor='right'
                open={isOpened}
            >
                <Box sx={{ pt: 5 }}>
                    <Grid
                        container
                        sx={{ width: { md: 320, xs: 'calc(100vw - 3.2rem)' } }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={12}
                            display='flex'
                            justifyContent='space-between'
                            sx={{ width: '100%' }}
                        >
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: 'absolute',
                                    top: '0.6rem',
                                    right: '0.6rem',
                                    mr: 2
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Typography
                        variant='h2'
                        style={{
                            marginTop: '-1rem',
                            paddingLeft: '1rem',
                            paddingBottom: '1rem'
                        }}
                    >
                        {'Notification Center'}
                    </Typography>
                    {items.length > 0 && (
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360
                            }}
                        >
                            {items.map((item: any, index: number) => (
                                <ListItem
                                    key={index}
                                    alignItems='flex-start'
                                    disablePadding
                                >
                                    <ListItemButton
                                        role={undefined}
                                        onClick={() =>
                                            feedClient.markAsRead(item)
                                        }
                                        dense={false}
                                    >
                                        <ListItemAvatar>
                                            <Avatar
                                                alt={item?.actors?.[0]?.name}
                                                src={item?.actors?.[0]?.avatar}
                                            />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: item
                                                            ?.blocks?.[0]
                                                            ?.rendered
                                                    }}
                                                />
                                            }
                                        />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    )}
                    {items.length <= 0 && (
                        <Typography
                            variant='body1'
                            style={{
                                paddingLeft: '1rem',
                                paddingBottom: '1rem'
                            }}
                        >
                            {'No new notifications.'}
                        </Typography>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default NotificationPanel;
