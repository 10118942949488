import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React, { Fragment, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import NotificationPanel from '../NotificationPanel/NotificationPanel';
import useNotifcationFeed from '../NotificationPanel/useNotifcationFeed';

const ProfilePictureBase = styled('div')<{ src: string }>(
    ({ theme, src }) => `
    background-color: ${theme.palette.secondary.main};
    border-radius: 50%;
    background-size: cover;
    background-position: center center;
    background-image: url(${src});
`
);

const ProfilePicture = styled(ProfilePictureBase)(() => ({
    height: 27,
    width: 27
}));

const PopoverContent = styled('div')`
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
`;

const StyledButton = styled(Button)`
    text-transform: none;
    display: flex;
    justify-content: flex-start;
`;

const content = {
    accountSettings: { text: 'Account Settings' },
    signOut: { text: 'Sign Out' }
};

export default function UserButton(props: {
    firstName: string;
    photoURL: string;
    signOut: () => void;
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLFormElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isOpened, setOpen] = useState(false);
    const notifButtonRef = useRef(null);

    const { uid } = useParams<{ uid: string }>();

    const { items, feedClient } = useNotifcationFeed();

    const handleClick = (event: React.ChangeEvent<HTMLFormElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Fragment>
            <>
                <IconButton
                    ref={notifButtonRef}
                    size='small'
                    aria-label='show new notifications'
                    color='primary'
                    onClick={() => {
                        setIsVisible(!isVisible);
                        setOpen(true);
                    }}
                >
                    <Badge
                        color='error'
                        variant='dot'
                        overlap='circular'
                        invisible={items.length <= 0}
                    >
                        <NotificationsNoneIcon />
                    </Badge>
                </IconButton>
            </>
            <StyledButton onClick={handleClick} id={'user-button'}>
                <Typography variant='body1' color='primary' sx={{ mr: 1.5 }}>
                    {props.firstName}
                </Typography>
                <ProfilePicture src={props.photoURL} />
            </StyledButton>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                disableScrollLock
            >
                <PopoverContent
                    style={{ borderRadius: '1rem', padding: '1rem' }}
                >
                    <StyledButton
                        component={Link}
                        to={`/edit/${uid}/candidate/name`}
                    >
                        <Typography color='primary'>
                            {content.accountSettings.text}
                        </Typography>
                    </StyledButton>
                    <StyledButton onClick={() => props.signOut()}>
                        <Typography color='primary'>
                            {content.signOut.text}
                        </Typography>
                    </StyledButton>
                </PopoverContent>
            </Popover>
            <NotificationPanel
                items={items}
                feedClient={feedClient}
                isOpened={isOpened}
                setOpen={setOpen}
            />
        </Fragment>
    );
}
