import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { CreateInterviewDetails } from '../../../types/interview';
import { CandidateSearch, SimpleJob } from '../../../types/job';
import { DateYMDString } from '../BookInterview/types';

import CalendarSlide from './CalendarSlide';
import CompleteSlide from './CompleteSlide';
import DescriptionSlide from './DescriptionSlide';
import EducationSlide from './EducationSlide';
import EnvironmentSlide from './EnvironmentSlide';
import IntroSlide from './IntroSlide';
import SalarySlide from './SalarySlide';
import TimesSlide from './TimesSlide';
import TitleSlide from './TitleSlide';

export const routes = {
    intro: { next: 'title', prev: 'complete' },
    title: { next: 'education', prev: 'intro' },
    education: { next: 'environment', prev: 'title' },
    environment: { next: 'salary', prev: 'education' },
    salary: { next: 'description', prev: 'environment' },
    description: { next: 'interviewcalendar', prev: 'salary' },
    interviewcalendar: { next: 'interviewtime', prev: 'description' },
    interviewtime: { next: 'complete', prev: 'interviewcalendar' },
    complete: { next: 'intro', prev: 'interviewtime' }
};

type JobPostRoutesProps = {
    path: string;
    job: SimpleJob;
    setJobAndRedirect: (job: SimpleJob, page: keyof typeof routes) => void;
    candidateCount: number;
    setCandidateCount: (count: number) => void;
    query: CandidateSearch;
    setQuery: (query: CandidateSearch) => void;
    date: DateYMDString;
    setDate: (date: DateYMDString) => void;
    interview: CreateInterviewDetails;
    setInterview: (interview: CreateInterviewDetails) => void;
    submitPosting: (
        jobDetails: SimpleJob,
        interviewDetails: CreateInterviewDetails
    ) => void;
    getPageURL: (page: keyof typeof routes, reverse?: boolean) => string;
    onClose: () => Promise<void>;
};

const JobPostRoutes = ({
    path,
    job,
    setJobAndRedirect,
    candidateCount,
    setCandidateCount,
    query,
    setQuery,
    date,
    setDate,
    interview,
    setInterview,
    submitPosting,
    getPageURL,
    onClose
}: JobPostRoutesProps) => (
    <Switch>
        <Route path={`${path}/intro`}>
            <IntroSlide nextURL={getPageURL('intro')} onClose={onClose} />
        </Route>
        <Route path={`${path}/title`}>
            <TitleSlide
                job={job}
                setJob={(newJob) => setJobAndRedirect(newJob, 'title')}
                candidateCount={candidateCount}
                setCandidateCount={setCandidateCount}
                query={query}
                setQuery={setQuery}
                prevURL={getPageURL('title', true)}
                onClose={onClose}
            />
        </Route>
        <Route path={`${path}/education`}>
            <EducationSlide
                job={job}
                setJob={(newJob) => setJobAndRedirect(newJob, 'education')}
                candidateCount={candidateCount}
                setCandidateCount={setCandidateCount}
                query={query}
                setQuery={setQuery}
                prevURL={getPageURL('education', true)}
                onClose={onClose}
            />
        </Route>
        <Route path={`${path}/environment`}>
            <EnvironmentSlide
                job={job}
                setJob={(newJob) => setJobAndRedirect(newJob, 'environment')}
                candidateCount={candidateCount}
                setCandidateCount={setCandidateCount}
                query={query}
                setQuery={setQuery}
                prevURL={getPageURL('environment', true)}
                onClose={onClose}
            />
        </Route>
        <Route path={`${path}/salary`}>
            <SalarySlide
                job={job}
                setJob={(newJob) => setJobAndRedirect(newJob, 'salary')}
                candidateCount={candidateCount}
                setCandidateCount={setCandidateCount}
                query={query}
                setQuery={setQuery}
                prevURL={getPageURL('salary', true)}
                onClose={onClose}
            />
        </Route>
        <Route path={`${path}/description`}>
            <DescriptionSlide
                job={job}
                setJob={(newJob) => setJobAndRedirect(newJob, 'description')}
                candidateCount={candidateCount}
                prevURL={getPageURL('description', true)}
                onClose={onClose}
            />
        </Route>
        <Route path={`${path}/interviewcalendar`}>
            <CalendarSlide
                date={date}
                setDate={setDate}
                interview={interview}
                setInterview={setInterview}
                candidateCount={candidateCount}
                nextURL={getPageURL('interviewcalendar')}
                prevURL={getPageURL('interviewcalendar', true)}
                onClose={onClose}
            />
        </Route>
        <Route path={`${path}/interviewtime`}>
            <TimesSlide
                currentDate={date}
                job={job}
                interview={interview}
                setInterview={setInterview}
                submitPosting={submitPosting}
                candidateCount={candidateCount}
                nextURL={getPageURL('interviewtime')}
                prevURL={getPageURL('interviewtime', true)}
                onClose={onClose}
            />
        </Route>
        <Route path={`${path}/complete`}>
            <CompleteSlide nextURL={getPageURL('complete')} />
        </Route>
    </Switch>
);

export default JobPostRoutes;
